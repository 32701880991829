import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { initialState } from '../context'

const richSnippetMain = ({ title, description, image, site, url }) =>
  `{
  "@context": "https://schema.org",
  "@type": "Organization",
  "@id": "${site}",
  "url": "${url}",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "${site}",
    "url": "${url}"
  },
  "name": "Reklama Dla Biznesu - ${title}",
  "headline": "${title}",
  "title": "${title}",
  "description": "${description}",
  "image": "${image}",
  "address": "Dąbrowszczaków 22, 80-365 Gdańsk, Polska",
  "logo": {
    "name": "Reklama Dla Biznesu",
    "@type": "ImageObject",
    "url": "${site}/icons/icon-512x512.png"
  },
  "geo": {
    "latitude": 54.423327888296285,
    "longitude": 18.56477034302407
  },
  "telephone": "+48 798 669 977"
}`

const richSnippetArticle = ({
  title,
  description,
  image,
  site,
  slug,
  date,
  url,
}) =>
  `{
  "@context": "https://schema.org",
  "@type": "NewsArticle",
  "@id": "${url}",
  "url": "${url}",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "${site}",
    "url": "${url}"
  },
  "datePublished": "${date}",
  "dateModified": "${date}",
  "headline": "${title}",
  "description": "${description}",
  "image": "${image}",
  "author": {
    "@type": "Organization",
    "name": "Reklama Dla Biznesu",
    "url": "https://reklamadlabiznesu.pl/"
  },
  "publisher": {
    "@type": "Organization",
    "name": "Reklama Dla Biznesu",
    "url": "${site}",
    "logo": {
      "@type": "ImageObject",
      "url": "${site}/icons/icon-512x512.png"
    }
  }
}`

function SEO({
  description,
  lang,
  meta,
  title,
  image,
  noIndex = false,
  isBlog,
  slug,
  date,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  // console.log('HERE' + site.siteMetadata.url + image)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}>
      <meta name="description" content={metaDescription} />

      <meta property="og:description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content={isBlog ? `article` : `website`} />
      <meta property="og:url" content={`${site.siteMetadata.url}${slug}`} />
      <meta
        property="og:image"
        content={
          image
            ? site.siteMetadata.url + image
            : site.siteMetadata.url + `/ogimage.jpg`
        }
      />
      <meta name="theme-color" content="#333333" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="ReklamaDlaBiznesu.pl" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta
        name="twitter:image"
        content={image ? image : site.siteMetadata.url + `/ogimage.jpg`}
      />
      {noIndex && <meta name="robots" content={'noindex,nofollow'} />}
      {noIndex && <meta name="googlebot" content={'noindex'} />}
      {noIndex && <meta name="googlebot-news" content={'nosnippet'} />}
      {noIndex && <meta name="AdsBot-Google" content={'noindex'} />}

      <link rel="canonical" href={`${site.siteMetadata.url}${slug}`} />
      {/* {keywords && <meta name="keywords" content={keywords} />} */}
      <script type="application/ld+json">
        {!isBlog
          ? richSnippetMain({
              title,
              description: metaDescription,
              image: image
                ? site.siteMetadata.url + image
                : site.siteMetadata.url + `/ogimage.jpg`,
              site: site.siteMetadata.url,
              url: `${site.siteMetadata.url}${slug}`,
            })
          : richSnippetArticle({
              date,
              title,
              description: metaDescription,
              image: image
                ? site.siteMetadata.url + image
                : site.siteMetadata.url + `/ogimage.jpg`,
              site: site.siteMetadata.url,
              slug,
              url: `${site.siteMetadata.url}${slug}`,
            })}
      </script>
    </Helmet>
  )

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:author`,
          content: site.siteMetadata.author,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `theme-color`,
          content: `#333333`,
        },
        {
          name: 'twitter:image',
          content: site.siteMetadata.url + (image || `/ogimage.jpg`),
        },
        {
          property: `og:image`,
          content: site.siteMetadata.url + (image || `/ogimage.jpg`),
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        noIndex
          ? { name: `robots`, content: 'noindex, nofollow' }
          : { name: `robots`, content: 'index' },
        noIndex
          ? { name: `googlebot`, content: 'noindex' }
          : { name: `googlebot`, content: 'index' },
        noIndex
          ? { name: `googlebot-news`, content: 'nosnippet' }
          : { name: `googlebot-news`, content: 'snippet' },
        noIndex
          ? { name: `AdsBot-Google`, content: 'noindex' }
          : { name: `AdsBot-Google`, content: 'index' },
      ].concat(meta)}>
      <link rel="preload" href="fonts/fonts.css" as="style" />
      <script type="application/ld+json">
        {!isBlog
          ? richSnippetMain({
              title,
              description: metaDescription,
              image: site.siteMetadata.url + (image || `/ogimage.jpg`),
              site: site.siteMetadata.url,
            })
          : richSnippetArticle({
              title,
              description: metaDescription,
              image: site.siteMetadata.url + (image || `/ogimage.jpg`),
              site: site.siteMetadata.url,
              slug,
              date,
            })}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: initialState.language || `pl`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
}

export default SEO
